import { NgFor } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';

@Component({
  selector: 'hb-group-modal',
  templateUrl: './group-modal.component.html',
  standalone: true,
  styleUrls: ['./group-modal.component.scss'],
  imports: [MatIconModule, MatButtonModule, MatFormFieldModule, MatSelectModule, MatOptionModule, FormsModule, NgFor]
})
export class GroupModalComponent implements OnInit {
  public selectedGroup: any;
  public allGroups: any[] = [];

  constructor(private dialogRef: MatDialogRef<GroupModalComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

  public ngOnInit(): void {
    this.allGroups = this.data;
  }

  public onClose(): void {
    this.dialogRef.close();
  }

  public onSubmit(): void {
    this.dialogRef.close(this.selectedGroup);
  }
}
