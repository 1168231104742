<header class="top-bar flex-row justify-center">
  <button class="close-icon cursor-pointer" (click)="onClose()" aria-label="Close">
    <mat-icon>close</mat-icon>
  </button>
  <h1 class="page-label" i18n>Group modal</h1>
</header>
<section class="group-form">
  <p class="option-text">
    Letters, as opposed to using 'Content here, content here', making it look like readable English. Many
  </p>
  <mat-form-field appearance="fill" class="custom-mat-form-field h-40">
    <mat-label class="mat-label" i18n>Group Title</mat-label>
    <mat-select panelClass="select-panel" required [(value)]="selectedGroup">
      <mat-option [value]="group" *ngFor="let group of allGroups">
        {{ group.title }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</section>
<footer class="submit-buttons flex-col space-between align-center gap-10">
  <button (click)="onSubmit()" class="apply flex-auto fill-button" mat-raised-button mat-dialog-close
    aria-label="Apply group">
    <span class="va-middle" i18n>Apply</span>
  </button>
  <button class="cancel outline-button" mat-raised-button (click)="onClose()" aria-label="Cancel group">
    <span class="va-middle" i18n>Cancel</span>
  </button>
</footer>